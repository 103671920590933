import React from "react";

const BlogHeader = ({}) => {
    return (
        <div>
            <img src="https://edit.choosemketech.org/wp-content/uploads/2021/12/Skyline_Milwaukee_east.png" alt="Milwaukee Skyline" />
        </div>
    )
}

export default BlogHeader