import React, { useState } from "react"

const Pagination = ({ postsPerPage, totalPosts, paginate }) => {
  const [activePage, setActivePage] = useState(0)

  const pageNumbers = []

  for (let i = 1; i <= Math.ceil(totalPosts / postsPerPage); i++) {
    pageNumbers.push(i)
  }

  const handlePaginate = (index) => {
    setActivePage(index)
    paginate(index)
  }

  return (
    <nav>
      <ul className="pagination">
        {pageNumbers.map((number) => (
          <li key={number} className={activePage === number && "active"}>
            <button onClick={() => handlePaginate(number)}>{number}</button>
          </li>
        ))}
      </ul>
    </nav>
  )
}

export default Pagination
