import React from "react"

const BlogFeaturedImage = ({ featuredImage }) => {
  if (!featuredImage?.node.sourceUrl) return
  return (
    <div className="blog-post__header__image">
      <img src={featuredImage.node.sourceUrl} alt={featuredImage.node.altText} />
    </div>
  )
}

export default BlogFeaturedImage
