export const CATEGORIES = {
    ["Software Developer"]: {
        label: "Software Developer",
        value: "Software Developer",
        displayName: "Software Developer",
        categoryClass: "tag tag--green tag--green--engineer"
    },
    ["Data + Analytics"]: {
        label: "Data + Analytics",
        value: "Data + Analytics",
        displayName: "Data + Analytics",
        categoryClass: "tag tag--green tag--green--data"
    },
    Sales: {
        label: "Sales",
        value: "Sales",
        displayName: "Sales",
        categoryClass: "tag tag--green tag--green--sales"
    },
    Content: {
        label: "Content",
        value: "Content",
        displayName: "Content",
        categoryClass: "tag tag--green tag--green--content"
    },
    Marketing: {
        label: "Marketing",
        value: "Marketing",
        displayName: "Marketing",
        categoryClass: "tag tag--green tag--green--marketing"
    },
    ["UX + Design"]: {
        label: "UX + Design",
        value: "UX + Design",
        displayName: "UX + Design",
        categoryClass: "tag tag--green tag--green--creative"
    },
    ["HR/Admin"]: {
        label: "HR/Admin",
        value: "HR/Admin",
        displayName: "HR/Admin",
        categoryClass: "tag tag--green tag--green--admin"
    },
    IT: {
        label: "IT",
        value: "IT",
        displayName: "IT",
        categoryClass: "tag tag--green tag--green--IT"
    },
    Product: {
        label: "Product",
        value: "Product",
        displayName: "Product",
        categoryClass: "tag tag--green tag--green--product"
    },
    Engineer: {
        label: "Engineer",
        value: "Engineer",
        displayName: "Engineer",
        categoryClass: "tag tag--green tag--green--engineer"
    }
};

export const CATEGORIES_LIST = Object.values(CATEGORIES);