import React, { useState } from "react"
import { graphql, useStaticQuery } from "gatsby"
import Layout from "../../components/Layout"
import { Breadcrumb } from "gatsby-plugin-breadcrumb"
import BlogHeader from "../../components/blog/BlogHeader"
import Pagination from "../../components/Pagination"
import BlogPostEntry from "../../components/blog/BlogPostEntry"
import BlogCarousel from "../../components/blog/BlogCarousel"

const Innovations = ({ pageContext }) => {
  const { allWpInnovation } = useStaticQuery(graphql`
    query Innovations {
      allWpInnovation {
        edges {
          node {
            content
            id
            title
            uri
            date
            link
            excerpt
            featuredImage {
              node {
                altText
                sourceUrl
              }
            }
            tags {
              nodes {
                name
              }
            }
            categories {
              nodes {
                name
              }
            }
          }
        }
      }
    }
  `)

  const innovations = allWpInnovation.edges

  const groupedPosts = innovations.reduce(
    (acc, curr) => {
      if (curr.node.tags.nodes.find((tag) => tag.name.toUpperCase() === "FEATURED")) {
        return {
          ...acc,
          featuredPosts: [...acc.featuredPosts, curr],
        }
      } else {
        return {
          ...acc,
          otherPosts: [...acc.otherPosts, curr],
        }
      }
    },
    { featuredPosts: [], otherPosts: [] }
  )

  const [selectedFilters, setSelectedFilters] = useState([])

  const [currentPage, setCurrentPage] = useState(1)
  const [postsPerPage, setPostsPerPage] = useState(5)
  const paginate = (pageNumber) => setCurrentPage(pageNumber)
  const paginationPosts = groupedPosts.otherPosts
    .filter((post) => {
      if (selectedFilters.length === 0) return true
      const node = post.node
      return node.categories.nodes.find((category) => selectedFilters.map((f) => f.toUpperCase()).includes(category.name.toUpperCase())) || node.tags.nodes.find((tag) => selectedFilters.map((f) => f.toUpperCase()).includes(tag.name.toUpperCase()))
    })
    .slice((currentPage - 1) * postsPerPage, currentPage * postsPerPage)

  return (
    <Layout>
      <Breadcrumb crumbs={pageContext.breadcrumb.crumbs} crumbSeparator=" / " />
      <BlogHeader />
      <section className="blog-listing">
        <h1>Innovations in the Region</h1>
        {groupedPosts.featuredPosts.length ? <BlogCarousel blogPosts={groupedPosts.featuredPosts.map((post) => post.node)} /> : null}
        <div className="pagination-container">
          <div className="paginate">
            <Pagination postsPerPage={postsPerPage} totalPosts={paginationPosts.length} paginate={paginate} />
          </div>
          <div className="pageinate-page">
            Displaying{" "}
            <div className="form-field dropdown ">
              <div className="select-container">
                <div className="select-container__select">
                  <select
                    onChange={(e) => {
                      setPostsPerPage(e.currentTarget.value)
                      setCurrentPage(1)
                    }}
                  >
                    <option>5</option>
                    <option>10</option>
                    <option>100</option>
                  </select>
                </div>
              </div>
            </div>
            of {paginationPosts.length} results
          </div>
        </div>
        {paginationPosts.map((post, index) => {
          return (
            <React.Fragment>
              <BlogPostEntry blogType="innovation" post={post.node} key={post.node.id} />
            </React.Fragment>
          )
        })}
        <div className="pagination-container">
          <div className="paginate">
            <Pagination postsPerPage={postsPerPage} totalPosts={paginationPosts.length} paginate={paginate} />
          </div>
          <div className="pageinate-page">
            Displaying{" "}
            <div className="form-field dropdown ">
              <div className="select-container">
                <div className="select-container__select">
                  <select
                    onChange={(e) => {
                      setPostsPerPage(e.currentTarget.value)
                      setCurrentPage(1)
                    }}
                  >
                    <option>5</option>
                    <option>25</option>
                    <option>100</option>
                  </select>
                </div>
              </div>
            </div>
            of {paginationPosts.length} results
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default Innovations
