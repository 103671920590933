import React from "react"

const renderBadges = (categories, categoryMap) => {
  return categories?.map((category) => {
    if (categoryMap[category]) {
      const { categoryClass, displayName } = categoryMap[category]
      return (
          <span key={displayName} className={categoryClass}>
          {displayName}
        </span>
      )
    }
  })
}

export default function Badges({ badgeList, badgeMap }) {
  return (
    <React.Fragment>
      {renderBadges(badgeList, badgeMap)}
    </React.Fragment>
  )
}